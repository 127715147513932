<template>
	<div
		:class="{[$style.wrapper]:true, [$style.loading]:loading}"
		v-else
	>
		<span
			v-if="error"
			:class="$style.error"
		>{{ error }}</span>
		<template v-else>
			<div>Мои блюда</div>
			<MealTypeList
				:class="$style.mealType"
				v-if="isReady"
				:currentMealGroup="currentMealGroup"
				:mealTypes="Object.keys(mealsNTypes)"
				:modeActive="false"
				@scrollToGroup="currentMealGroup = $event; scrollMealGroup($event)"
			/>
			<div
				:class="$style.list"
				ref="wrapper"
				@scroll="checkActualGroup"
			>
				<MyDishList
					v-for="meals, type in mealsNTypes"
					:type="type"
					:meals="meals"
					:key="type"
					@changeMeal="currentMeal = $event; showPopup=true"
					ref="list"
				/>
				<div :style="{height: end + 'px'}"></div>
			</div>
		</template>
		<ChangeMealPopup
			:show="showPopup"
			:data="mealInPopup"
			@closePopup="showPopup=false"
			@changeMeal="mealInPopup = $event; showPopup=false"
		/>
	</div>
</template>

<script>
import ChangeMealPopup from '@/components/block/Rest/ChangeMealPopup.vue';
import MealTypeList from '@/components/block/Rest/MealTypeList.vue';
import MyDishList from '@/components/block/Rest/MyDishList.vue';
import {mapMutations, mapState, mapActions, mapGetters} from "vuex";

import throttle from 'lodash/throttle'

export default {
	data()
	{
		return {
			types: [],
			currentMealGroup: 0,
			mealsNTypes: {},
			end: 0,
			currentMeal: {},
			showPopup: false,
		}
	},
	components: {MealTypeList, MyDishList, ChangeMealPopup},
	computed: {
		...mapState('lkmenu', ['allMeals', 'isReady', 'loading', 'error']),
		mealInPopup:
			{
				set: function (val)
				{
					this.mealsNTypes[this.currentMeal.type][this.currentMeal.id].sostav = val.sostav
					this.mealsNTypes[this.currentMeal.type][this.currentMeal.id].name = val.name
					this.mealsNTypes[this.currentMeal.type][this.currentMeal.id].weight = val.weight
					this.mealsNTypes[this.currentMeal.type][this.currentMeal.id].kkal = val.kkal
					this.mealsNTypes[this.currentMeal.type][this.currentMeal.id].belki = val.belki
					this.mealsNTypes[this.currentMeal.type][this.currentMeal.id].jiri = val.jiri
					this.mealsNTypes[this.currentMeal.type][this.currentMeal.id].uglevodi = val.uglevodi
					this.mealsNTypes[this.currentMeal.type][this.currentMeal.id].price = val.price
				},
				get: function ()
				{
					return this.currentMeal.type ? this.mealsNTypes[this.currentMeal.type][this.currentMeal.id] : null;
				}
			}
	},
	methods: {
		...mapActions('lkmenu', ['init']),
		fillMealsList()
		{
			for (let num in this.allMeals)
			{
				let el = this.allMeals[num]
				if (el.type === 'Вторые')
					el.type = 'Горячие'
				if (!(el.type in this.mealsNTypes))
				{
					this.mealsNTypes[el.type] = []
				}
				this.mealsNTypes[el.type].push(el)
			}
		},
		async scrollMealGroup(mealType)
		{
			await this.$nextTick()

			let i = this.types.findIndex(el => el === mealType)

			if (i !== -1) this.$refs.list[i].$el.scrollIntoView(true);
		},
		checkActualGroup: throttle(
			async function ()
			{
				await this.$nextTick()
				let scrollTop = this.$refs.wrapper.getBoundingClientRect().top;

				let prev = this.types[0]

				for (let i = 0; i < this.types.length; i += 1)
				{
					prev = this.types[i]
					let rect = this.$refs.list[i].$el.getBoundingClientRect()
					if ((rect.bottom) / 2 - scrollTop > 0)
					{
						break
					}
				}
				this.currentMealGroup = prev;
			},
			200
		),
	},
	async mounted()
	{
		await this.init();
		this.fillMealsList();
		this.types = Object.keys(this.mealsNTypes)
		this.currentMealGroup = this.types[0];
		this.end = window.innerHeight - 230
	},
}
</script>

<style
	lang="scss"
	module
>
.wrapper
{
	display: grid;
	font-family: 'Roboto', 'PT Sans', sans-serif;
	grid:
                "name" 30px
                "typeList" 60px
                "dishList" 1fr
            / 1fr;

	@media (min-height: 500px)
	{
		grid-template-rows: 30px 80px 1fr;
	}

	padding: 10px;
	gap: 10px 10px;
	overflow: hidden;

	.name
	{
		grid-area: name;
	}

	.typeList
	{
		grid-area: typeList;

		border-radius: 10px;
	}

	.dishList
	{
		grid-area: dishList;
	}

}

.list
{
	overflow-y: scroll;
}

.loading:before
{
	content: "";
	position: absolute;
	top: 0;
	left: 0;

	height: 3px;
	width: 100vw;

	@keyframes animate-stripes
	{
		0%
		{
			background-position: 0 0;
		}
		100%
		{
			background-position: 100vw 0;
		}
	}

	background-image: linear-gradient(
			90deg,
			#40AE49 50%,
			transparent 50%
	);
	animation: animate-stripes 5s linear infinite;
}

.error
{
	margin: 20px;
	font-size: 20px;
	color: #FD470E;
}
</style>